import React from 'react';

const UserRights = () => {
  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Your Data Rights</h1>

      <section className="mb-4">
        <h2 className="text-xl font-semibold">Right to Access</h2>
        <p>You have the right to request access to your personal data that we collect and store.</p>
      </section>

      <section className="mb-4">
        <h2 className="text-xl font-semibold">Right to Deletion</h2>
        <p>You can request the deletion of your personal data from our records at any time.</p>
      </section>

      <section className="mb-4">
        <h2 className="text-xl font-semibold">Right to Correction</h2>
        <p>If your personal data is incorrect or outdated, you have the right to request that it be corrected.</p>
      </section>

      <section className="mb-4">
        <h2 className="text-xl font-semibold">Right to Data Portability</h2>
        <p>You have the right to request a copy of your data in a machine-readable format that can be transferred to another service.</p>
      </section>

      <section className="mb-4">
        <h2 className="text-xl font-semibold">Right to Object</h2>
        <p>You can object to the processing of your personal data in certain circumstances.</p>
      </section>
      
    </div>
  );
};

export default UserRights;
