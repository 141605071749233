// src/components/ViewBudget.js

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import React, { useState, useEffect } from 'react';
import { database, auth } from '../firebase-config';
import { ref, update, onValue } from 'firebase/database';
import {
  startOfMonth,
  endOfMonth,
  addWeeks,
  addMonths,
  addYears,
} from 'date-fns';


// Import the DataExport and DataImport components
import DataExport from './DataExport';
import DataImport from './DataImport';


// Import Modal from react-modal
import Modal from 'react-modal';

// Set the app element for accessibility (required for react-modal)
Modal.setAppElement('#root');

const ViewBudget = () => {
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [expenseData, setExpenseData] = useState([]);
  const [incomeData, setIncomeData] = useState([]);
  const currentDate = new Date();
  const currentMonth = (currentDate.getMonth() + 1).toString(); // getMonth() is zero-indexed, so we add 1
  const currentYear = currentDate.getFullYear().toString();

  const [filterMonth, setFilterMonth] = useState(currentMonth);
  const [filterYear, setFilterYear] = useState(currentYear);
  const [editMode, setEditMode] = useState({});

  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

    // Register components with Chart.js
    ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);
  // Expanded color palette for 13 categories
  const categoryColors = [
    '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
    '#FF9F40', '#FF5733', '#33FF57', '#3357FF', '#FF33A1',
    '#33FFA1', '#A133FF', '#FFA133',
  ];
  // Calculate data for the Doughnut chart with percentages
  const aggregateExpensesByCategory = (expenses) => {
    const categoryTotals = {};
  
    expenses.forEach((expense) => {
      const category = expense.category.trim().toLowerCase();
      const totalAmount = expense.totalAmount;
  
      // If the category already exists, add to its total; otherwise, initialize it
      if (categoryTotals[category]) {
        categoryTotals[category] += totalAmount;
      } else {
        categoryTotals[category] = totalAmount;
      }
    });
  
    return categoryTotals;
  };
  
  // Aggregate expenses by category
  const aggregatedCategoryTotals = aggregateExpensesByCategory(expenseData);
  
  // Generate labels and data arrays for the chart
  const labels = Object.keys(aggregatedCategoryTotals);
  const data = Object.values(aggregatedCategoryTotals);
  
  // Updated categorySpendingData for the chart
  const categorySpendingData = {
    labels: labels,
    datasets: [
      {
        label: 'Spending Breakdown',
        data: data,
        backgroundColor: categoryColors, // Your pre-defined color array
        hoverOffset: 6,
      },
    ],
  };
  
  // Options for the Doughnut chart with legend on the left
  const chartOptions = {
    plugins: {
      legend: {
        position: 'left', // Positioning legend to the left side of the chart
        labels: {
          font: {
            size: 14,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const data = tooltipItem.raw;
            const total = tooltipItem.chart.data.datasets[0].data.reduce((acc, value) => acc + value, 0);
            const percentage = ((data / total) * 100).toFixed(2);
            return `${tooltipItem.label}: $${data} (${percentage}%)`;
          },
        },
      },
    },
  };

  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (!userId) return;

    // Fetch and calculate income
    const incomeRef = ref(database, `incomes/${userId}`);
    onValue(incomeRef, (snapshot) => {
      const data = snapshot.val();
      const incomeArray = [];
      if (data) {
        Object.entries(data).forEach(([key, income]) => {
          const occurrences = getOccurrencesInMonth(income, filterYear, filterMonth);
          const totalAmount = occurrences.length * parseFloat(income.amount || 0);
          if (totalAmount > 0) {
            incomeArray.push({
              id: key,
              ...income,
              occurrences,
              totalAmount,
            });
          }
        });
      }
      setIncomeData(incomeArray);
      const totalIncomeAmount = incomeArray.reduce((acc, inc) => acc + inc.totalAmount, 0);
      setTotalIncome(totalIncomeAmount);
    });

    // Fetch and calculate expenses
    const expenseRef = ref(database, `expenses/${userId}`);
    onValue(expenseRef, (snapshot) => {
      const data = snapshot.val();
      const expenseArray = [];
      if (data) {
        Object.entries(data).forEach(([key, expense]) => {
          const occurrences = getOccurrencesInMonth(expense, filterYear, filterMonth);
          const totalAmount = occurrences.length * parseFloat(expense.amount || 0);
          const totalAmountPaid = parseFloat(expense.amountPaid || 0);
          if (totalAmount > 0) {
            expenseArray.push({
              id: key,
              ...expense,
              occurrences,
              totalAmount,
              totalAmountPaid,
            });
          }
        });
      }
      setExpenseData(expenseArray);
      const totalExpenseAmount = expenseArray.reduce((acc, exp) => acc + exp.totalAmount, 0);
      setTotalExpenses(totalExpenseAmount);
    });
  }, [filterMonth, filterYear]);

  // Function to get occurrences of a transaction in a given month
  const getOccurrencesInMonth = (transaction, year, month) => {
    const occurrences = [];
    const transactionDate = transaction.date ? new Date(transaction.date) : null;
    if (!transactionDate || isNaN(transactionDate)) return occurrences;

    const startDate = startOfMonth(new Date(year, month - 1));
    const endDate = endOfMonth(startDate);

    if (!transaction.isRecurring || !transaction.recurrenceFrequency) {
      // Non-recurring transaction
      if (
        transactionDate.getMonth() + 1 === Number(month) &&
        transactionDate.getFullYear() === Number(year)
      ) {
        occurrences.push(transactionDate);
      }
    } else {
      // Recurring transaction
      let currentDate = new Date(Math.max(transactionDate, startDate));

      while (currentDate <= endDate) {
        if (transaction.recurrenceEndDate) {
          const recurrenceEndDate = new Date(transaction.recurrenceEndDate);
          if (currentDate > recurrenceEndDate) break;
        }

        if (
          currentDate.getMonth() + 1 === Number(month) &&
          currentDate.getFullYear() === Number(year)
        ) {
          occurrences.push(new Date(currentDate));
        }

        switch (transaction.recurrenceFrequency) {
          case 'weekly':
            currentDate = addWeeks(currentDate, 1);
            break;
          case 'bi-weekly':
            currentDate = addWeeks(currentDate, 2);
            break;
          case 'monthly':
            currentDate = addMonths(currentDate, 1);
            break;
          case 'yearly':
            currentDate = addYears(currentDate, 1);
            break;
          default:
            currentDate = new Date(endDate.getTime() + 1); // Exit the loop
            break;
        }
      }
    }
    return occurrences;
  };

  // Function to handle updating the amount paid
  const handleAmountPaidUpdate = async (id, newAmountPaid, totalAmount) => {
    const userId = auth.currentUser?.uid;
    if (!userId) return;

    const parsedAmount = parseFloat(newAmountPaid);

    if (parsedAmount > totalAmount) {
      const confirmOverBudget = window.confirm(
        `The amount paid ($${parsedAmount}) exceeds the total budget for this expense ($${totalAmount}). Do you want to proceed?`
      );
      if (!confirmOverBudget) {
        return; // Exit without updating if the user cancels
      }
    }

    const expenseRef = ref(database, `expenses/${userId}/${id}`);
    try {
      await update(expenseRef, { amountPaid: parsedAmount });
      setEditMode((prevState) => ({ ...prevState, [id]: false })); // Exit edit mode after saving
    } catch (error) {
      console.error('Error updating amount paid:', error);
    }
  };

  // Function to calculate progress percentage
  const calculateProgress = (amountPaid, totalAmount) => {
    if (!totalAmount) return 0;
    const progress = ((amountPaid / totalAmount) * 100).toFixed(2);
    return Number(progress);
  };

  // Function to handle toggling the edit mode for an entry
  const handleEditToggle = (id) => {
    setEditMode((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  
// Function to handle checkbox change
const handleCheckboxChange = (id, isChecked, totalAmount) => {
  const newAmountPaid = isChecked ? totalAmount : 0;
  handleAmountPaidUpdate(id, newAmountPaid, totalAmount);
};

  // Generate options for months
  const monthOptions = [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];

  // Generate options for years
  const yearOptions = [];
  for (let year = Number(currentYear) - 5; year <= Number(currentYear) + 5; year++) {
    yearOptions.push(
      <option key={year} value={year.toString()}>
        {year}
      </option>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      {/* Header Section */}
      <div className="max-w-7xl mx-auto px-4 lg:px-8 py-6">
          <div className="flex flex-col items-start space-y-4">
            <h3 className="text-2xl font-semibold text-green-700">Monthly Budget Overview</h3>
            <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
             <button
               onClick={() => setIsImportModalOpen(true)}
                className="bg-blue-600 text-white px-4 py-2 rounded-md shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
             >
               Import Data
              </button>
             <button
                onClick={() => setIsExportModalOpen(true)}
                className="bg-green-600 text-white px-4 py-2 rounded-md shadow hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
             >
               Export Data
             </button>
           </div>
          </div>
        </div>

        {/* Month and Year Filter */}
        <div className="flex flex-wrap items-center justify-center space-x-4 mb-6">
          <select
            value={filterMonth}
            onChange={(e) => setFilterMonth(e.target.value)}
            className="w-40 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
          >
            {monthOptions.map((month) => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
          </select>
          <select
            value={filterYear}
            onChange={(e) => setFilterYear(e.target.value)}
            className="w-32 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
          >
            {yearOptions}
          </select>
        </div>



        {/* Display Budget Summary */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-6 w-full max-w-7xl mx-auto">
          <div className="w-full p-4 bg-green-50 rounded-lg shadow flex flex-col items-center text-center">
            <h4 className="text-sm sm:text-md font-semibold text-green-800 mb-1">Total Income</h4>
            <p className="text-lg sm:text-2xl font-bold text-green-600">${totalIncome.toFixed(2)}</p>
          </div>
          <div className="w-full p-4 bg-red-50 rounded-lg shadow flex flex-col items-center text-center">
            <h4 className="text-sm sm:text-md font-semibold text-red-800 mb-1">Total Expenses</h4>
            <p className="text-lg sm:text-2xl font-bold text-red-600">${totalExpenses.toFixed(2)}</p>
          </div>
          <div className="w-full p-4 bg-blue-50 rounded-lg shadow flex flex-col items-center text-center">
            <h4 className="text-sm sm:text-md font-semibold text-blue-800 mb-1">Net Balance</h4>
          <p className="text-lg sm:text-2xl font-bold text-blue-600">
              ${(totalIncome - totalExpenses).toFixed(2)}
           </p>
          </div>
        </div>
      
        
      
      


        {/* Integrate the chart into JSX code in the render section*/}

        <div className="max-w-7xl mx-auto px-4 lg:px-8 py-6">
          <h3 className="text-2xl sm:text-3xl font-semibold text-gray-800 mb-4 text-center">Category Spending Breakdown</h3>
          <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md flex justify-center">
            <div className="w-full max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl">
              <Doughnut data={categorySpendingData} options={chartOptions} />
            </div>
          </div>
        </div>

{/* Expense Breakdown */}
<div className="max-w-7xl mx-auto px-4 lg:px-8 py-6">
  <h3 className="text-2xl font-semibold text-gray-800 mb-4 text-center">Expense Breakdown</h3>
  <div className="overflow-x-auto">
    <table className="min-w-full bg-white text-center table-auto lg:table-fixed border-collapse border border-gray-200 shadow-md rounded-lg">
      <thead>
        <tr style={{ background: 'linear-gradient(to right, #4CAF50, #2E7D32)' }}>
          <th className="py-3 px-2 sm:px-4 border-b text-sm font-medium text-white">Expense</th>
          <th className="py-3 px-2 sm:px-4 border-b text-sm font-medium text-white">Total Amount ($)</th>
          <th className="py-3 px-2 sm:px-4 border-b text-sm font-medium text-white">Amount Paid ($)</th>
          <th className="py-3 px-2 sm:px-4 border-b text-sm font-medium text-white">% Paid</th>
          <th className="py-3 px-2 sm:px-4 border-b text-sm font-medium text-white">Progress</th>
          <th className="py-3 px-2 sm:px-4 border-b text-sm font-medium text-white">Actions</th>
          <th className="py-3 px-2 sm:px-4 border-b text-sm font-medium text-white">100% Paid</th>
        </tr>
      </thead>
      <tbody>
        {expenseData.length > 0 ? (
          expenseData.map((expense, index) => {
            const progress = calculateProgress(expense.totalAmountPaid || 0, expense.totalAmount);
            const progressBarValue = Math.min(progress, 100);
            const progressBarColor =
              progress <= 75 ? 'bg-yellow-500' : progress <= 100 ? 'bg-green-500' : 'bg-red-500';

            const isFullyPaid = expense.totalAmountPaid >= expense.totalAmount;

            return (
              <tr key={index} className="bg-white">
                <td className="py-4 px-2 sm:px-4 border-b border-gray-300 text-gray-700 font-semibold">
                  <span className="block text-base uppercase font-bold">{expense.description}</span>
                  <span className="block text-sm text-gray-500 uppercase">{expense.category}</span>
                </td>
                <td className="py-4 px-2 sm:px-4 border-b border-gray-300 text-gray-700 font-semibold">
                  ${expense.totalAmount.toFixed(2)}
                </td>
                <td className="py-4 px-2 sm:px-4 border-b border-gray-300 text-gray-700 font-semibold">
                  {editMode[expense.id] ? (
                    <input
                      type="number"
                      min="0"
                      defaultValue={expense.totalAmountPaid}
                      className="w-20 p-2 border border-gray-300 rounded-md text-center"
                      onBlur={(e) =>
                        handleAmountPaidUpdate(expense.id, e.target.value, expense.totalAmount)
                      }
                    />
                  ) : (
                    `$${(expense.totalAmountPaid || 0).toFixed(2)}`
                  )}
                </td>
                <td className="py-4 px-2 sm:px-4 border-b border-gray-300 text-gray-700 font-semibold">{progress}%</td>
                <td className="py-4 px-2 sm:px-4 border-b border-gray-300 text-gray-700">
                  <div className="w-full bg-gray-200 rounded-full h-4">
                    <div
                      className={`h-4 rounded-full ${progressBarColor}`}
                      style={{ width: `${progressBarValue}%` }}
                    ></div>
                  </div>
                </td>
                <td className="py-4 px-2 sm:px-4 border-b border-gray-300 text-gray-700">
                  <button
                    onClick={() => handleEditToggle(expense.id)}
                    className="bg-blue-500 text-white px-2 sm:px-3 py-1 rounded-md hover:bg-blue-600 transition duration-300"
                  >
                    {editMode[expense.id] ? 'Save' : 'Edit'}
                  </button>
                </td>
                <td className="py-4 px-2 sm:px-4 border-b border-gray-300 text-center">
                  <label className="flex items-center justify-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={isFullyPaid}
                      onChange={(e) => handleCheckboxChange(expense.id, e.target.checked, expense.totalAmount)}
                      className="form-checkbox h-4 w-4 text-green-600"
                    />
                  </label>
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="7" className="py-6 px-4 text-center text-gray-600">
              No expenses to display for the selected month and year.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
</div>


{/* Income Breakdown */}
<div className="max-w-7xl mx-auto px-4 lg:px-8 py-6">
  <h3 className="text-2xl font-semibold text-gray-800 mb-4 text-center">Monthly Income Breakdown</h3>
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
    {incomeData.map((income, index) => (
      <div key={index} className="p-4 bg-white rounded-lg shadow-md flex flex-col items-center text-center">
        <h4 className="text-lg font-semibold text-gray-800 mb-2">{income.source}</h4>
        <p className="text-2xl font-bold text-green-600">${income.totalAmount.toFixed(2)}</p>
        <p className="text-sm text-gray-500">({((income.totalAmount / totalIncome) * 100).toFixed(2)}% of total)</p>
      </div>
    ))}
  </div>
</div>





      {/* Import Modal */}
      <Modal
        isOpen={isImportModalOpen}
        onRequestClose={() => setIsImportModalOpen(false)}
        contentLabel="Import Data"
        className="fixed inset-0 flex items-center justify-center z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      >
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
          <div className="flex justify-between items-center mb-4">
            <h4 className="text-lg font-semibold text-gray-800">Import Data</h4>
            <button
              onClick={() => setIsImportModalOpen(false)}
              className="text-gray-600 hover:text-gray-800 focus:outline-none text-2xl leading-none"
            >
              &times;
            </button>
          </div>
          <DataImport onClose={() => setIsImportModalOpen(false)} />
        </div>
      </Modal>

      {/* Export Modal */}
      <Modal
        isOpen={isExportModalOpen}
        onRequestClose={() => setIsExportModalOpen(false)}
        contentLabel="Export Data"
        className="fixed inset-0 flex items-center justify-center z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      >
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
          <div className="flex justify-between items-center mb-4">
            <h4 className="text-lg font-semibold text-gray-800">Export Data</h4>
            <button
              onClick={() => setIsExportModalOpen(false)}
              className="text-gray-600 hover:text-gray-800 focus:outline-none text-2xl leading-none"
            >
              &times;
            </button>
          </div>
          <DataExport onClose={() => setIsExportModalOpen(false)} />
        </div>
      </Modal>
    </div>
  );
};


export default ViewBudget;














































































































