// src/components/ExpenseForm.js

import React, { useState, useEffect } from 'react';
import { database, auth } from '../firebase-config';
import { ref, push, onValue, update, remove } from 'firebase/database';
import toast from 'react-hot-toast';


const ExpenseForm = () => {
  // State variables
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState('');
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurrenceFrequency, setRecurrenceFrequency] = useState('');
  const [recurrenceEndDate, setRecurrenceEndDate] = useState('');
  const [expenseData, setExpenseData] = useState([]);
  const [editId, setEditId] = useState(null);

  const currentDate = new Date();
  const currentMonth = (currentDate.getMonth() + 1).toString(); // getMonth() is zero-indexed
  const currentYear = currentDate.getFullYear().toString();

  const [filterMonth, setFilterMonth] = useState(currentMonth);
  const [filterYear, setFilterYear] = useState(currentYear);

  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (!userId) return;

    const expenseRef = ref(database, `expenses/${userId}`);
    onValue(expenseRef, (snapshot) => {
      const data = snapshot.val();
      const expenseArray = data
        ? Object.entries(data).map(([key, value]) => ({ id: key, ...value }))
        : [];
      setExpenseData(expenseArray);
    });
  }, []);

  // Function to handle adding or updating an expense
  const handleAddOrUpdateExpense = (e) => {
    e.preventDefault();
    const userId = auth.currentUser?.uid;
    if (!userId) {
      toast.error('You must be logged in to add or edit expenses.');
      return;
    }

    const expenseRef = ref(database, `expenses/${userId}`);
    const currentStartDate = date || new Date().toISOString().split('T')[0];

    const parsedDate = new Date(currentStartDate);
    if (isNaN(parsedDate)) {
      toast.error('Invalid date. Please enter a valid date.');
      return;
    }

    // Adjust the date to account for timezone differences
    parsedDate.setHours(parsedDate.getHours() + 12);

    let parsedRecurrenceEndDate = null;
    if (recurrenceEndDate) {
      parsedRecurrenceEndDate = new Date(recurrenceEndDate);
      if (isNaN(parsedRecurrenceEndDate)) {
        toast.error('Invalid recurrence end date. Please enter a valid date.');
        return;
      }
      parsedRecurrenceEndDate.setHours(parsedRecurrenceEndDate.getHours() + 12);
    }

    const expenseDataObj = {
      amount: parseFloat(amount),
      date: parsedDate.toISOString(), // Store date in ISO format
      category,
      description,
      isRecurring,
      recurrenceFrequency,
      recurrenceEndDate: parsedRecurrenceEndDate
        ? parsedRecurrenceEndDate.toISOString()
        : null,
    };

    if (editId) {
      const expenseToUpdate = ref(database, `expenses/${userId}/${editId}`);
      update(expenseToUpdate, expenseDataObj)
        .then(() => {
          resetForm();
          toast.success('Expense updated successfully');
        })
        .catch((error) => {
          toast.error(`Failed to update expense: ${error.message}`);
        });
    } else {
      push(expenseRef, expenseDataObj)
        .then(() => {
          resetForm();
          toast.success('Expense added successfully');
        })
        .catch((error) => {
          toast.error(`Failed to add expense: ${error.message}`);
        });
    }
  };

  // Function to reset the form fields
  const resetForm = () => {
    setEditId(null);
    setAmount('');
    setDate('');
    setCategory('');
    setDescription('');
    setIsRecurring(false);
    setRecurrenceFrequency('');
    setRecurrenceEndDate('');
  };

  // Function to handle editing an expense
  const handleEdit = (expense) => {
    setEditId(expense.id);
    setAmount(expense.amount);

    let expenseDate = '';
    if (expense.date) {
      if (typeof expense.date === 'string') {
        expenseDate = expense.date.split('T')[0];
      } else if (expense.date instanceof Date) {
        expenseDate = expense.date.toISOString().split('T')[0];
      } else {
        console.warn('Unexpected date type:', expense.date);
        expenseDate = '';
      }
    }

    let recurrenceEnd = '';
    if (expense.recurrenceEndDate) {
      if (typeof expense.recurrenceEndDate === 'string') {
        recurrenceEnd = expense.recurrenceEndDate.split('T')[0];
      } else if (expense.recurrenceEndDate instanceof Date) {
        recurrenceEnd = expense.recurrenceEndDate.toISOString().split('T')[0];
      } else {
        console.warn('Unexpected recurrence end date type:', expense.recurrenceEndDate);
        recurrenceEnd = '';
      }
    }

    setDate(expenseDate);
    setCategory(expense.category);
    setDescription(expense.description || '');
    setIsRecurring(expense.isRecurring || false);
    setRecurrenceFrequency(expense.recurrenceFrequency || '');
    setRecurrenceEndDate(recurrenceEnd);
  };

  // Function to handle deleting an expense
  const handleDelete = (id) => {
    const userId = auth.currentUser?.uid;
    const expenseToDelete = ref(database, `expenses/${userId}/${id}`);
    remove(expenseToDelete)
      .then(() => {
        toast.success('Expense deleted successfully');
      })
      .catch((error) => {
        toast.error(`Failed to delete expense: ${error.message}`);
      });
  };

  // Generate options for months
  const monthOptions = [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];

  // Generate options for years (from currentYear - 5 to currentYear + 5)
  const yearOptions = [];
  for (let year = Number(currentYear) - 5; year <= Number(currentYear) + 5; year++) {
    yearOptions.push(
      <option key={year} value={year.toString()}>
        {year}
      </option>
    );
  }

  return (
    <div className="max-w-5xl mx-auto p-6 bg-white rounded-lg shadow-md mb-8">
      {/* Month and Year Filter Section at the Top */}
      <div className="flex flex-wrap items-center justify-center space-x-4 mb-6">
        <select
          value={filterMonth}
          onChange={(e) => setFilterMonth(e.target.value)}
          className="w-40 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
        >
          {monthOptions.map((month) => (
            <option key={month.value} value={month.value}>
              {month.label}
            </option>
          ))}
        </select>
        <select
          value={filterYear}
          onChange={(e) => setFilterYear(e.target.value)}
          className="w-32 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
        >
          {yearOptions}
        </select>
      </div>

      <h3 className="text-xl font-semibold text-green-700 mb-6 text-center">
        Manage Your Expenses
      </h3>

      <form onSubmit={handleAddOrUpdateExpense} className="space-y-6">
        {/* Form Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {/* Amount */}
          <div>
            <label className="block text-sm font-medium text-gray-800">Amount:</label>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              required
            />
          </div>
          {/* Date */}
          <div>
            <label className="block text-sm font-medium text-gray-800">Date:</label>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              required
            />
          </div>
          {/* Category */}
          <div>
            <label className="block text-sm font-medium text-gray-800">Category:</label>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              required
            >
              <option value="">Select a category</option>
              <option value="bills">Bills</option>
              <option value="housing">Housing</option>
              <option value="groceries">Groceries</option>
              <option value="transportation">Transportation</option>
              <option value="entertainment">Entertainment</option>
              <option value="health">Health</option>
              <option value="education">Education</option>
              <option value="shopping">Shopping</option>
              <option value="travel">Travel</option>
              <option value="subscriptions">Subscriptions</option>
              <option value="insurance">Insurance</option>
              <option value="dining out">Dining Out</option>
              <option value="miscellaneous">Miscellaneous</option>
            </select>
          </div>
          {/* Description */}
          <div className="sm:col-span-2 md:col-span-3">
            <label className="block text-sm font-medium text-gray-800">Description:</label>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              required
            />
          </div>
        </div>

        {/* Recurring Expense Section */}
        <div className="border-t border-gray-200 pt-6">
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={isRecurring}
              onChange={(e) => setIsRecurring(e.target.checked)}
              className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
            />
            <label className="ml-2 text-sm font-medium text-gray-800">
              Is this a recurring expense?
            </label>
          </div>

          {isRecurring && (
            <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              {/* Recurrence Frequency */}
              <div>
                <label className="block text-sm font-medium text-gray-800">
                  Recurrence Frequency:
                </label>
                <select
                  value={recurrenceFrequency}
                  onChange={(e) => setRecurrenceFrequency(e.target.value)}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                >
                  <option value="">Select frequency</option>
                  <option value="weekly">Weekly</option>
                  <option value="bi-weekly">Bi-weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                </select>
              </div>
              {/* Recurrence End Date */}
              <div>
                <label className="block text-sm font-medium text-gray-800">
                  Recurrence End Date:
                </label>
                <input
                  type="date"
                  value={recurrenceEndDate}
                  onChange={(e) => setRecurrenceEndDate(e.target.value)}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                />
              </div>
            </div>
          )}
        </div>

        {/* Submit Button */}
        <div className="text-center">
          <button
            type="submit"
            className="w-full sm:w-auto px-6 py-2 bg-green-600 text-white rounded-md shadow hover:bg-green-700"
          >
            {editId ? 'Update Expense' : 'Add Expense'}
          </button>
        </div>
      </form>

      {/* Expenses List */}
      <h4 className="text-lg font-bold mb-4 mt-10 text-center">Your Expenses</h4>
      {expenseData.length > 0 ? (
        <ul className="space-y-4">
          {expenseData.map((expense) => {
            // Only display expenses that match the selected month and year
            const expenseDate = new Date(expense.date);
            if (
              expenseDate.getMonth() + 1 === Number(filterMonth) &&
              expenseDate.getFullYear() === Number(filterYear)
            ) {
              return (
                <li
                  key={expense.id}
                  className="border p-4 rounded-lg shadow-sm flex flex-col md:flex-row justify-between items-start md:items-center"
                >
                  <div className="mb-4 md:mb-0 md:flex-1">
                    <p className="text-sm">
                      <strong>Category:</strong> {expense.category}
                    </p>
                    <p className="text-sm">
                      <strong>Amount:</strong> ${parseFloat(expense.amount).toFixed(2)}
                    </p>
                    <p className="text-sm">
                      <strong>Date:</strong>{' '}
                      {expense.date
                        ? new Date(expense.date).toLocaleDateString()
                        : 'No Date'}
                    </p>
                    {expense.isRecurring && (
                      <>
                        <p className="text-sm">
                          <strong>Recurrence:</strong> {expense.recurrenceFrequency}
                        </p>
                        {expense.recurrenceEndDate && (
                          <p className="text-sm">
                            <strong>Recurs Until:</strong>{' '}
                            {new Date(expense.recurrenceEndDate).toLocaleDateString()}
                          </p>
                        )}
                      </>
                    )}
                    {expense.description && (
                      <p className="text-sm">
                        <strong>Description:</strong> {expense.description}
                      </p>
                    )}
                  </div>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleEdit(expense)}
                      className="bg-blue-500 text-white px-3 py-1 rounded shadow hover:bg-blue-600 text-sm"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(expense.id)}
                      className="bg-red-500 text-white px-3 py-1 rounded shadow hover:bg-red-600 text-sm"
                    >
                      Delete
                    </button>
                  </div>
                </li>
              );
            } else {
              return null;
            }
          })}
        </ul>
      ) : (
        <p className="text-center">No expenses to display for the selected month and year</p>
      )}
    </div>
  );
};

export default ExpenseForm;













































