import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      
      <section className="mb-4">
        <h2 className="text-xl font-semibold">Introduction</h2>
        <p>At Atlas Budget, your privacy is very important to us. This Privacy Policy explains how we collect, use, and protect your data.</p>
      </section>

      <section className="mb-4">
        <h2 className="text-xl font-semibold">Data Collection</h2>
        <p>We collect information that you provide directly when you sign up, use our services, or interact with our website. This includes your name, email, income, expenses, and other financial data.</p>
      </section>

      <section className="mb-4">
        <h2 className="text-xl font-semibold">Data Usage</h2>
        <p>We use your data to provide, maintain, and improve our services, personalize your experience, and for security purposes.</p>
      </section>

      <section className="mb-4">
        <h2 className="text-xl font-semibold">Data Sharing</h2>
        <p>We do not sell or rent your data to third parties. However, we may share information with service providers who assist us in operating our services, subject to strict data protection measures.</p>
      </section>

      <section className="mb-4">
        <h2 className="text-xl font-semibold">Data Security</h2>
        <p>We implement industry-standard security measures to protect your data from unauthorized access, disclosure, or destruction.</p>
      </section>

      <section className="mb-4">
        <h2 className="text-xl font-semibold">User Rights</h2>
        <p>You have the right to access, modify, or delete your personal data. Please contact us if you wish to exercise these rights.</p>
      </section>

      <section className="mb-4">
        <h2 className="text-xl font-semibold">Changes to This Policy</h2>
        <p>We may update this policy periodically. We will notify you of any significant changes by posting the new policy on our website.</p>
      </section>

      <section>
        <h2 className="text-xl font-semibold">Contact Information</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at support@atlasbudget.com.</p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;