import React, { useState, useEffect } from 'react';
import { auth, database } from '../firebase-config'; // Adjust the import based on your config
import { ref, get, set } from 'firebase/database';
import { uploadBytes, getDownloadURL, ref as storageRef } from 'firebase/storage'; // Import storage methods
import { storage } from '../firebase-config'; // Adjust this import based on your storage config

const Profile = () => {
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(true);
    const [file, setFile] = useState(null);
    
    useEffect(() => {
        const user = auth.currentUser;
        if (user) {
            const userRef = ref(database, 'users/' + user.uid);
            get(userRef).then(snapshot => {
                if (snapshot.exists()) {
                    setUserData(snapshot.val());
                }
                setLoading(false);
            });
        }
    }, []);

    const handleUpdate = () => {
        const user = auth.currentUser;
        set(ref(database, 'users/' + user.uid), userData).then(() => {
            alert('Profile updated successfully!');
        }).catch((error) => {
            console.error('Error updating profile:', error);
        });
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (file) {
            const user = auth.currentUser;
            const storageReference = storageRef(storage, `avatars/${user.uid}`);
            await uploadBytes(storageReference, file);
            const downloadURL = await getDownloadURL(storageReference);
            set(ref(database, 'users/' + user.uid), { ...userData, avatar: downloadURL });
            alert('Avatar uploaded successfully!');
        }
    };

    if (loading) return <div className="flex justify-center items-center min-h-screen bg-gray-100">Loading...</div>;

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-2xl font-semibold text-center text-gray-800 mb-6">Profile</h2>
                
                {userData.avatar && <img src={userData.avatar} alt="Avatar" className="w-24 h-24 rounded-full mx-auto mb-4" />}
                
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Name:</label>
                    <input
                        type="text"
                        value={userData.name || ''}
                        onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                        placeholder="Name"
                        className="mt-1 block w-full p-3 border border-gray-300 rounded-md"
                    />
                </div>
                
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Email:</label>
                    <input
                        type="email"
                        value={userData.email || ''}
                        onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                        placeholder="Email"
                        className="mt-1 block w-full p-3 border border-gray-300 rounded-md"
                        readOnly
                    />
                </div>
                
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Upload Avatar:</label>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="mt-1 block w-full"
                    />
                    <button
                        onClick={handleUpload}
                        className="mt-2 w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 focus:outline-none"
                    >
                        Upload Avatar
                    </button>
                </div>
                
                <button
                    onClick={handleUpdate}
                    className="w-full bg-blue-600 text-white py-3 rounded-md hover:bg-blue-700 focus:outline-none"
                >
                    Update Profile
                </button>
            </div>
        </div>
    );
};

export default Profile;


