// src/components/SavingsGoalForm.js

import React, { useState, useEffect } from 'react';
import { database, auth } from '../firebase-config';
import { ref, push, onValue, update, remove, get } from 'firebase/database';
import { format } from 'date-fns';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import toast from 'react-hot-toast';
import ProgressBar from 'react-bootstrap/ProgressBar';

// Register components with Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SavingsGoalForm = () => {
  // State variables for the form
  const [goalName, setGoalName] = useState('');
  const [goalAmount, setGoalAmount] = useState('');
  const [startDate, setStartDate] = useState('');
  const [targetDate, setTargetDate] = useState('');
  const [description, setDescription] = useState('');
  const [currentSavings, setCurrentSavings] = useState(0);
  const [savingsGoalsData, setSavingsGoalsData] = useState([]);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (!userId) return;

    const goalRef = ref(database, `savingsGoals/${userId}`);
    onValue(goalRef, (snapshot) => {
      const data = snapshot.val();
      const goalArray = data
        ? Object.entries(data).map(([key, value]) => ({ id: key, ...value }))
        : [];
      setSavingsGoalsData(goalArray);
    });
  }, []);

  const handleAddOrUpdateGoal = (e) => {
    e.preventDefault();
    const userId = auth.currentUser?.uid;
    if (!userId) {
      toast.error('You must be logged in to set or edit a savings goal.');
      return;
    }

    const goalRef = ref(database, `savingsGoals/${userId}`);

    const parsedGoalAmount = parseFloat(goalAmount);
    if (isNaN(parsedGoalAmount) || parsedGoalAmount <= 0) {
      toast.error('Please enter a valid goal amount.');
      return;
    }

    const parsedStartDate = startDate ? new Date(startDate) : new Date();
    const parsedTargetDate = targetDate ? new Date(targetDate) : null;
    if (!parsedTargetDate || isNaN(parsedTargetDate)) {
      toast.error('Please enter a valid target date.');
      return;
    }

    if (parsedStartDate > parsedTargetDate) {
      toast.error('Start date cannot be after target date.');
      return;
    }

    const goalDataObj = {
      goalName,
      goalAmount: parsedGoalAmount,
      startDate: parsedStartDate.toISOString(),
      targetDate: parsedTargetDate.toISOString(),
      description,
      currentSavings: parseFloat(currentSavings) || 0,
      contributions: {},
    };

    if (editId) {
      // Update savings goal
      const goalToUpdate = ref(database, `savingsGoals/${userId}/${editId}`);
      update(goalToUpdate, goalDataObj)
        .then(() => {
          resetForm();
          toast.success('Savings goal updated successfully');
        })
        .catch((error) => {
          toast.error(`Failed to update savings goal: ${error.message}`);
        });
    } else {
      // Add savings goal
      push(goalRef, goalDataObj)
        .then(() => {
          resetForm();
          toast.success('Savings goal added successfully');
        })
        .catch((error) => {
          toast.error(`Failed to add savings goal: ${error.message}`);
        });
    }
  };

  const resetForm = () => {
    setEditId(null);
    setGoalName('');
    setGoalAmount('');
    setStartDate('');
    setTargetDate('');
    setDescription('');
    setCurrentSavings(0);
  };

  const handleEdit = (goalItem) => {
    setEditId(goalItem.id);
    setGoalName(goalItem.goalName);
    setGoalAmount(goalItem.goalAmount);
    setStartDate(goalItem.startDate ? goalItem.startDate.split('T')[0] : '');
    setTargetDate(goalItem.targetDate ? goalItem.targetDate.split('T')[0] : '');
    setDescription(goalItem.description || '');
    setCurrentSavings(goalItem.currentSavings || 0);
  };

  const handleDelete = (id) => {
    const userId = auth.currentUser?.uid;
    const goalToDelete = ref(database, `savingsGoals/${userId}/${id}`);
    remove(goalToDelete)
      .then(() => {
        toast.success('Savings goal deleted successfully');
      })
      .catch((error) => {
        toast.error(`Failed to delete savings goal: ${error.message}`);
      });
  };

  const handleAddContribution = async (goalId, amount) => {
    const userId = auth.currentUser?.uid;
    if (!userId) return;

    const contributionAmount = parseFloat(amount);
    if (isNaN(contributionAmount) || contributionAmount <= 0) {
      toast.error('Please enter a valid contribution amount.');
      return;
    }

    const goalRef = ref(database, `savingsGoals/${userId}/${goalId}`);

    try {
      const snapshot = await get(goalRef);
      const goalData = snapshot.val();
      if (goalData) {
        // Update currentSavings
        const newCurrentSavings = (goalData.currentSavings || 0) + contributionAmount;

        // Add contribution to contributions array
        const contributions = goalData.contributions || [];

        // Generate a valid key for the contribution
        const contributionDate = new Date();
        const formattedDate = format(contributionDate, 'yyyyMMddHHmmss');

        // Add the contribution object
        contributions.push({
          id: formattedDate,
          amount: contributionAmount,
          date: contributionDate.toISOString(),
        });

        // Update the goal data
        await update(goalRef, {
          currentSavings: newCurrentSavings,
          contributions: contributions,
        });
        toast.success('Contribution added successfully');
      }
    } catch (error) {
      toast.error(`Failed to add contribution: ${error.message}`);
    }
  };

  return (
    <div className="max-w-5xl mx-auto p-6 bg-white rounded-lg shadow-md mb-8">
      <h3 className="text-xl font-semibold text-green-700 mb-6 text-center">
        Manage Your Savings Goals
      </h3>

      <form onSubmit={handleAddOrUpdateGoal} className="space-y-6">
        {/* Form Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {/* Goal Name */}
          <div>
            <label className="block text-sm font-medium text-gray-800">Goal Name:</label>
            <input
              type="text"
              value={goalName}
              onChange={(e) => setGoalName(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              required
            />
          </div>
          {/* Goal Amount */}
          <div>
            <label className="block text-sm font-medium text-gray-800">Goal Amount:</label>
            <input
              type="number"
              value={goalAmount}
              onChange={(e) => setGoalAmount(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              required
            />
          </div>
          {/* Start Date */}
          <div>
            <label className="block text-sm font-medium text-gray-800">Start Date:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
            />
          </div>
          {/* Target Date */}
          <div>
            <label className="block text-sm font-medium text-gray-800">Target Date:</label>
            <input
              type="date"
              value={targetDate}
              onChange={(e) => setTargetDate(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              required
            />
          </div>
          {/* Description */}
          <div className="sm:col-span-2 md:col-span-3">
            <label className="block text-sm font-medium text-gray-800">Description:</label>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="text-center">
          <button
            type="submit"
            className="w-full sm:w-auto px-6 py-2 bg-green-600 text-white rounded-md shadow hover:bg-green-700"
          >
            {editId ? 'Update Savings Goal' : 'Add Savings Goal'}
          </button>
        </div>
      </form>

      {/* Savings Goals List */}
      <h4 className="text-lg font-bold mb-4 mt-10 text-center">Your Savings Goals</h4>
      {savingsGoalsData.length > 0 ? (
        <ul className="space-y-4">
          {savingsGoalsData.map((goalItem) => {
            // Calculate progress
            const progressPercentage = Math.min(
              ((goalItem.currentSavings || 0) / goalItem.goalAmount) * 100,
              100
            ).toFixed(2);

            return (
              <li
                key={goalItem.id}
                className="border p-4 rounded-lg shadow-sm flex flex-col md:flex-row justify-between items-start md:items-center"
              >
                <div className="mb-4 md:mb-0 md:flex-1">
                  <p className="text-sm">
                    <strong>Goal Name:</strong> {goalItem.goalName}
                  </p>
                  <p className="text-sm">
                    <strong>Goal Amount:</strong> ${goalItem.goalAmount.toFixed(2)}
                  </p>
                  <p className="text-sm">
                    <strong>Current Savings:</strong> $
                    {(goalItem.currentSavings || 0).toFixed(2)}
                  </p>
                  <p className="text-sm">
                    <strong>Progress:</strong> {progressPercentage}%
                  </p>
                  <ProgressBar
                    now={progressPercentage}
                    label={`${progressPercentage}%`}
                    variant={progressPercentage >= 100 ? 'success' : 'info'}
                    className="my-2"
                  />
                  <p className="text-sm">
                    <strong>Start Date:</strong>{' '}
                    {goalItem.startDate
                      ? new Date(goalItem.startDate).toLocaleDateString()
                      : 'N/A'}
                  </p>
                  <p className="text-sm">
                    <strong>Target Date:</strong>{' '}
                    {goalItem.targetDate
                      ? new Date(goalItem.targetDate).toLocaleDateString()
                      : 'N/A'}
                  </p>
                  {goalItem.description && (
                    <p className="text-sm">
                      <strong>Description:</strong> {goalItem.description}
                    </p>
                  )}
                  {/* Chart of contributions */}
                  {goalItem.contributions && goalItem.contributions.length > 0 && (
                    <div className="mt-4">
                      <Bar
                        data={{
                          labels: goalItem.contributions
                            .sort((a, b) => new Date(a.date) - new Date(b.date))
                            .map((contribution) => format(new Date(contribution.date), 'MM/dd/yyyy')),
                          datasets: [
                            {
                              label: 'Contributions',
                              data: goalItem.contributions
                                .sort((a, b) => new Date(a.date) - new Date(b.date))
                                .map((contribution) => contribution.amount),
                              backgroundColor: '#34D399',
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          scales: {
                            x: {
                              title: {
                                display: true,
                                text: 'Date',
                              },
                            },
                            y: {
                              title: {
                                display: true,
                                text: 'Amount',
                              },
                              beginAtZero: true,
                            },
                          },
                        }}
                        height={200}
                      />
                    </div>
                  )}
                </div>
                <div className="flex flex-col space-y-2">
                  <button
                    onClick={() => handleEdit(goalItem)}
                    className="bg-blue-500 text-white px-3 py-1 rounded shadow hover:bg-blue-600 text-sm"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(goalItem.id)}
                    className="bg-red-500 text-white px-3 py-1 rounded shadow hover:bg-red-600 text-sm"
                  >
                    Delete
                  </button>
                  {/* Button to add contribution */}
                  <button
                    onClick={() => {
                      const amount = prompt('Enter contribution amount:');
                      if (amount) {
                        handleAddContribution(goalItem.id, amount);
                      }
                    }}
                    className="bg-green-500 text-white px-3 py-1 rounded shadow hover:bg-green-600 text-sm"
                  >
                    Add Contribution
                  </button>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <p className="text-center">No savings goals to display</p>
      )}
    </div>
  );
};

export default SavingsGoalForm;



