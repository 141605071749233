// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ user, loading, children }) => {
  if (loading) {
    // While loading, you can render a loading indicator or null
    return null; // or a loading spinner
  }

  return user ? children : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
