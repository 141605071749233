// src/components/DataImport.js

import React, { useState } from 'react';
import Papa from 'papaparse';
import { database, auth } from '../firebase-config';
import { ref, set, push } from 'firebase/database';

const DataImport = ({ onClose }) => {
  const [importType, setImportType] = useState('expenses');
  const [file, setFile] = useState(null);
  const [isImporting, setIsImporting] = useState(false);
  const [error, setError] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError('');
  };

  const handleImport = () => {
    if (!file) {
      setError('Please select a CSV file to import.');
      return;
    }

    setIsImporting(true);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        const userId = auth.currentUser?.uid;
        if (!userId) {
          alert('You must be logged in to import data.');
          setIsImporting(false);
          return;
        }

        const data = results.data;
        const processedData = [];

        try {
          for (const item of data) {
            // Validate required fields
            if (!item.amount || !item.date) {
              continue; // Skip invalid entries
            }

            // Parse amount and date
            const amount = parseFloat(item.amount);
            const date = new Date(item.date);

            if (isNaN(amount) || isNaN(date.getTime())) {
              continue; // Skip invalid entries
            }

            // Create a transaction object
            const transaction = {
              amount,
              date: date.toISOString(),
              category: item.category || '',
              description: item.description || '',
              isRecurring: item.isRecurring === 'true',
              recurrenceFrequency: item.recurrenceFrequency || '',
              recurrenceEndDate: item.recurrenceEndDate || '',
            };

            // Push to Firebase
            const dataRef = ref(
              database,
              `${importType}/${userId}/${push(ref(database)).key}`
            );
            await set(dataRef, transaction);

            processedData.push(transaction);
          }

          
          alert(`${processedData.length} transactions imported successfully.`);

          // Close the modal after import
          if (onClose) {
            onClose();
          }
        } catch (err) {
          console.error('Error importing data:', err);
          setError('An error occurred while importing data.');
        } finally {
          setIsImporting(false);
        }
      },
      error: (err) => {
        console.error('Error parsing CSV:', err);
        setError('An error occurred while parsing the CSV file.');
        setIsImporting(false);
      },
    });
  };

  return (
    <div>
      {/* Data Type Selection */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-800">
          Data Type:
        </label>
        <select
          value={importType}
          onChange={(e) => setImportType(e.target.value)}
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
        >
          <option value="expenses">Expenses</option>
          <option value="incomes">Incomes</option>
        </select>
      </div>

      {/* File Input */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-800">
          Select CSV File:
        </label>
        <input
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          className="mt-1 block w-full text-sm text-gray-700 border border-gray-300 rounded-md cursor-pointer focus:outline-none"
        />
      </div>

      {/* Error Message */}
      {error && <p className="text-red-600 mb-4">{error}</p>}

      {/* Import Button */}
      <div className="text-center">
        <button
          onClick={handleImport}
          disabled={isImporting}
          className={`w-full sm:w-auto px-6 py-2 rounded-md shadow text-white ${
            isImporting
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-green-600 hover:bg-green-700'
          }`}
        >
          {isImporting ? 'Importing...' : 'Import Data'}
        </button>
      </div>
    </div>
  );
};

export default DataImport;
