import React, { useState, useEffect } from 'react';
import { auth, database } from '../firebase-config';
import { ref, set, get } from 'firebase/database';
import { useNavigate } from 'react-router-dom';

const ProfileManager = () => {
  const [profiles, setProfiles] = useState([]);
  const [currentProfileId, setCurrentProfileId] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const user = auth.currentUser;
    console.log("Current User:", user);

    if (user) {
      const userRef = ref(database, 'users/' + user.uid + '/profiles');
      get(userRef).then(snapshot => {
        if (snapshot.exists()) {
          setProfiles(snapshot.val());
          setCurrentProfileId(Object.keys(snapshot.val())[0]); // Set the first profile as current
        } else {
          console.log('No profiles found.');
        }
        setLoading(false);
      });
    } else {
      console.log('No user is logged in.');
      navigate('/login'); // Redirect to login if no user
    }
  }, [navigate]);

  const switchProfile = (profileId) => {
    setCurrentProfileId(profileId);
  };

  const addProfile = () => {
    const user = auth.currentUser;
    if (user && profiles.length < 5) {
      const newProfileId = 'profileId' + (profiles.length + 1);
      set(ref(database, `users/${user.uid}/profiles/${newProfileId}`), { name: "New Profile", avatarUrl: "/default-avatar.png" });
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div>
      <h1>Profile Manager</h1>
      <button onClick={addProfile}>Add Profile</button>
      <ul>
        {Object.keys(profiles).length === 0 ? (
          <li>No profiles available. Please add one.</li>
        ) : (
          Object.keys(profiles).map(profileId => (
            <li key={profileId} onClick={() => switchProfile(profileId)}>
              {profiles[profileId].name} {currentProfileId === profileId && "(Current)"}
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default ProfileManager;



















