// src/components/IncomeForm.js

import React, { useState, useEffect } from 'react';
import { database, auth } from '../firebase-config';
import { ref, push, onValue, update, remove } from 'firebase/database';
import toast from 'react-hot-toast';


const IncomeForm = () => {
  // State variables
  const [source, setSource] = useState('');
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurrenceFrequency, setRecurrenceFrequency] = useState('');
  const [recurrenceEndDate, setRecurrenceEndDate] = useState('');
  const [incomeData, setIncomeData] = useState([]);
  const [editId, setEditId] = useState(null);

  const currentDate = new Date();
  const currentMonth = (currentDate.getMonth() + 1).toString(); // getMonth() is zero-indexed
  const currentYear = currentDate.getFullYear().toString();

  const [filterMonth, setFilterMonth] = useState(currentMonth);
  const [filterYear, setFilterYear] = useState(currentYear);

  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (!userId) return;

    const incomeRef = ref(database, `incomes/${userId}`);
    onValue(incomeRef, (snapshot) => {
      const data = snapshot.val();
      const incomeArray = data
        ? Object.entries(data).map(([key, value]) => ({ id: key, ...value }))
        : [];
      setIncomeData(incomeArray);
    });
  }, []);

  // Function to handle adding or updating an income
  const handleAddOrUpdateIncome = (e) => {
    e.preventDefault();
    const userId = auth.currentUser?.uid;
    if (!userId) {
      toast.error('You must be logged in to add or edit income.');
      return;
    }

    const incomeRef = ref(database, `incomes/${userId}`);
    const currentStartDate = date || new Date().toISOString().split('T')[0];

    const parsedDate = new Date(currentStartDate);
    if (isNaN(parsedDate)) {
      toast.error('Invalid date. Please enter a valid date.');
      return;
    }

    // Adjust the date to account for timezone differences
    parsedDate.setHours(parsedDate.getHours() + 12);

    let parsedRecurrenceEndDate = null;
    if (recurrenceEndDate) {
      parsedRecurrenceEndDate = new Date(recurrenceEndDate);
      if (isNaN(parsedRecurrenceEndDate)) {
        toast.error('Invalid recurrence end date. Please enter a valid date.');
        return;
      }
      parsedRecurrenceEndDate.setHours(parsedRecurrenceEndDate.getHours() + 12);
    }

    const incomeDataObj = {
      source,
      amount: parseFloat(amount),
      date: parsedDate.toISOString(),
      description,
      isRecurring,
      recurrenceFrequency,
      recurrenceEndDate: parsedRecurrenceEndDate
        ? parsedRecurrenceEndDate.toISOString()
        : null,
    };

    if (editId) {
      const incomeToUpdate = ref(database, `incomes/${userId}/${editId}`);
      update(incomeToUpdate, incomeDataObj)
        .then(() => {
          resetForm();
          toast.success('Income updated successfully');
        })
        .catch((error) => {
          toast.error(`Failed to update income: ${error.message}`);
        });
    } else {
      push(incomeRef, incomeDataObj)
        .then(() => {
          resetForm();
          toast.success('Income added successfully');
        })
        .catch((error) => {
          toast.error(`Failed to add income: ${error.message}`);
        });
    }
  };

  // Function to reset the form fields
  const resetForm = () => {
    setEditId(null);
    setSource('');
    setAmount('');
    setDate('');
    setDescription('');
    setIsRecurring(false);
    setRecurrenceFrequency('');
    setRecurrenceEndDate('');
  };

  // Function to get occurrences of a transaction in a given month
  const getOccurrencesInMonth = (transaction, year, month) => {
    const occurrences = [];
    const transactionDate = transaction.date ? new Date(transaction.date) : null;
    if (!transactionDate || isNaN(transactionDate)) return occurrences;

    const startDate = new Date(year, month - 1, 1);
    const endDate = new Date(year, month, 0);

    if (!transaction.isRecurring || !transaction.recurrenceFrequency) {
      // Non-recurring transaction
      if (
        transactionDate.getMonth() + 1 === Number(month) &&
       transactionDate.getFullYear() === Number(year)
      ) {
       occurrences.push(transactionDate);
     }
    } else {
     // Recurring transaction
     let currentDate = new Date(Math.max(transactionDate, startDate));

      while (currentDate <= endDate) {
        if (transaction.recurrenceEndDate) {
         const recurrenceEndDate = new Date(transaction.recurrenceEndDate);
         if (currentDate > recurrenceEndDate) break;
       }

        if (
         currentDate.getMonth() + 1 === Number(month) &&
         currentDate.getFullYear() === Number(year)
       ) {
         occurrences.push(new Date(currentDate));
       }

        switch (transaction.recurrenceFrequency) {
         case 'weekly':
            currentDate.setDate(currentDate.getDate() + 7);
           break;
          case 'bi-weekly':
            currentDate.setDate(currentDate.getDate() + 14);
            break;
         case 'monthly':
            currentDate.setMonth(currentDate.getMonth() + 1);
            break;
          case 'yearly':
            currentDate.setFullYear(currentDate.getFullYear() + 1);
           break;
          default:
           currentDate = new Date(endDate.getTime() + 1); // Exit the loop
            break;
       }
      }
    }
   return occurrences;
  };


  // Function to handle editing an income
  const handleEdit = (income) => {
    setEditId(income.id);
    setSource(income.source);
    setAmount(income.amount);

    let incomeDate = '';
    if (income.date) {
      if (typeof income.date === 'string') {
        incomeDate = income.date.split('T')[0];
      } else if (income.date instanceof Date) {
        incomeDate = income.date.toISOString().split('T')[0];
      } else {
        console.warn('Unexpected date type:', income.date);
        incomeDate = '';
      }
    }

    let recurrenceEnd = '';
    if (income.recurrenceEndDate) {
      if (typeof income.recurrenceEndDate === 'string') {
        recurrenceEnd = income.recurrenceEndDate.split('T')[0];
      } else if (income.recurrenceEndDate instanceof Date) {
        recurrenceEnd = income.recurrenceEndDate.toISOString().split('T')[0];
      } else {
        console.warn('Unexpected recurrence end date type:', income.recurrenceEndDate);
        recurrenceEnd = '';
      }
    }

    setDate(incomeDate);
    setDescription(income.description || '');
    setIsRecurring(income.isRecurring || false);
    setRecurrenceFrequency(income.recurrenceFrequency || '');
    setRecurrenceEndDate(recurrenceEnd);
  };

  // Function to handle deleting an income
  const handleDelete = (id) => {
    const userId = auth.currentUser?.uid;
    if (!userId) return;
    const incomeToDelete = ref(database, `incomes/${userId}/${id}`);
    remove(incomeToDelete)
      .then(() => {
        toast.success('Income deleted successfully');
      })
      .catch((error) => {
        toast.error(`Failed to delete income: ${error.message}`);
      });
  };

  // Generate options for months
  const monthOptions = [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];

  // Generate options for years (from currentYear - 5 to currentYear + 5)
  const yearOptions = [];
  for (let year = Number(currentYear) - 5; year <= Number(currentYear) + 5; year++) {
    yearOptions.push(
      <option key={year} value={year.toString()}>
        {year}
      </option>
    );
  }

  return (
    <div className="max-w-5xl mx-auto p-6 bg-white rounded-lg shadow-md mb-8">
      {/* Month and Year Filter Section at the Top */}
      <div className="flex flex-wrap items-center justify-center space-x-4 mb-6">
        <select
          value={filterMonth}
          onChange={(e) => setFilterMonth(e.target.value)}
          className="w-40 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
        >
          {monthOptions.map((month) => (
            <option key={month.value} value={month.value}>
              {month.label}
            </option>
          ))}
        </select>
        <select
          value={filterYear}
          onChange={(e) => setFilterYear(e.target.value)}
          className="w-32 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
        >
          {yearOptions}
        </select>
      </div>

      <h3 className="text-xl font-semibold text-green-700 mb-6 text-center">
        Manage Your Income
      </h3>

      <form onSubmit={handleAddOrUpdateIncome} className="space-y-6">
        {/* Form Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {/* Source */}
          <div>
            <label className="block text-sm font-medium text-gray-800">Source:</label>
            <input
              type="text"
              value={source}
              onChange={(e) => setSource(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              required
            />
          </div>
          {/* Amount */}
          <div>
            <label className="block text-sm font-medium text-gray-800">Amount:</label>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              required
            />
          </div>
          {/* Date */}
          <div>
            <label className="block text-sm font-medium text-gray-800">Date:</label>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              required
            />
          </div>
          {/* Description */}
          <div className="sm:col-span-2 md:col-span-3">
            <label className="block text-sm font-medium text-gray-800">Description:</label>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
            />
          </div>
        </div>

        {/* Recurring Income Section */}
        <div className="border-t border-gray-200 pt-6">
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={isRecurring}
              onChange={(e) => setIsRecurring(e.target.checked)}
              className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
            />
            <label className="ml-2 text-sm font-medium text-gray-800">
              Is this a recurring income?
            </label>
          </div>

          {isRecurring && (
            <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              {/* Recurrence Frequency */}
              <div>
                <label className="block text-sm font-medium text-gray-800">
                  Recurrence Frequency:
                </label>
                <select
                  value={recurrenceFrequency}
                  onChange={(e) => setRecurrenceFrequency(e.target.value)}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                >
                  <option value="">Select frequency</option>
                  <option value="weekly">Weekly</option>
                  <option value="bi-weekly">Bi-weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                </select>
              </div>
              {/* Recurrence End Date */}
              <div>
                <label className="block text-sm font-medium text-gray-800">
                  Recurrence End Date:
                </label>
                <input
                  type="date"
                  value={recurrenceEndDate}
                  onChange={(e) => setRecurrenceEndDate(e.target.value)}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                />
              </div>
            </div>
          )}
        </div>

        {/* Submit Button */}
        <div className="text-center">
          <button
            type="submit"
            className="w-full sm:w-auto px-6 py-2 bg-green-600 text-white rounded-md shadow hover:bg-green-700"
          >
            {editId ? 'Update Income' : 'Add Income'}
          </button>
        </div>
      </form>

      {/* Income List */}
      <h4 className="text-lg font-bold mb-4 mt-10 text-center">Your Income</h4>
      {incomeData.length > 0 ? (
        <ul className="space-y-4">
          {incomeData.map((income) => {
            // Get occurrences of income for the selected month and year
            const occurrences = getOccurrencesInMonth(income, filterYear, filterMonth);
            if (occurrences.length > 0) {
             return (
               <li
                 key={income.id}
                  className="border p-4 rounded-lg shadow-sm flex flex-col md:flex-row justify-between items-start md:items-center"
               >
                  <div className="mb-4 md:mb-0 md:flex-1">
                   <p className="text-sm">
                     <strong>Source:</strong> {income.source}
                   </p>
                   <p className="text-sm">
                     <strong>Amount:</strong> ${parseFloat(income.amount).toFixed(2)}
                    </p>
                   <p className="text-sm">
                     <strong>Date:</strong> {occurrences.map(date => date.toLocaleDateString()).join(', ')}
                    </p>
                    {income.isRecurring && (
                     <>
                        <p className="text-sm">
                         <strong>Recurrence:</strong> {income.recurrenceFrequency}
                        </p>
                        {income.recurrenceEndDate && (
                          <p className="text-sm">
                           <strong>Recurs Until:</strong> {new Date(income.recurrenceEndDate).toLocaleDateString()}
                          </p>
                       )}
                      </>
                   )}
                    {income.description && (
                     <p className="text-sm">
                       <strong>Description:</strong> {income.description}
                      </p>
                    )}
                  </div>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleEdit(income)}
                      className="bg-blue-500 text-white px-3 py-1 rounded shadow hover:bg-blue-600 text-sm"
                   >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(income.id)}
                      className="bg-red-500 text-white px-3 py-1 rounded shadow hover:bg-red-600 text-sm"
                  >
                     Delete
                    </button>
                  </div>
                </li>
              );
            } else {
              return null;
           }
          })}
        </ul>
      ) : (
        <p className="text-center">No income to display for the selected month and year</p>
      )}
    </div>
  );
};

export default IncomeForm;


























