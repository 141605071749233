// src/components/DataExport.js

import React, { useState } from 'react';
import { database, auth } from '../firebase-config';
import { ref, get } from 'firebase/database';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

const DataExport = ({ onClose }) => {
  const [exportType, setExportType] = useState('expenses');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isExporting, setIsExporting] = useState(false);

  const handleExport = async () => {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      alert('You must be logged in to export data.');
      return;
    }

    setIsExporting(true);

    let dataRef;
    if (exportType === 'expenses') {
      dataRef = ref(database, `expenses/${userId}`);
    } else if (exportType === 'incomes') {
      dataRef = ref(database, `incomes/${userId}`);
    } else if (exportType === 'savingsGoals') {
      dataRef = ref(database, `savingsGoals/${userId}`);
    }

    try {
      const snapshot = await get(dataRef);
      const data = snapshot.val();
      if (data) {
        let dataArray = Object.values(data).map((item) => ({ id: item.id || '', ...item }));

        // Filter data by date range if provided
        if (startDate) {
          const start = new Date(startDate);
          dataArray = dataArray.filter((item) => new Date(item.date || item.startDate) >= start);
        }
        if (endDate) {
          const end = new Date(endDate);
          dataArray = dataArray.filter((item) => new Date(item.date || item.startDate) <= end);
        }

        if (dataArray.length === 0) {
          alert('No data available for the selected date range.');
          setIsExporting(false);
          return;
        }

        // Convert data to CSV
        const csv = Papa.unparse(dataArray);

        // Save the CSV file
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `${exportType}-${Date.now()}.csv`);

        // Close the modal after export
        if (onClose) {
          onClose();
        }
      } else {
        alert('No data available to export.');
      }
    } catch (error) {
      console.error('Error exporting data:', error);
      alert('An error occurred while exporting data.');
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleExport();
      }}
      className="space-y-6"
    >
      {/* Data Type Selection */}
      <div>
        <label className="block text-sm font-medium text-gray-800">
          Data Type:
        </label>
        <select
          value={exportType}
          onChange={(e) => setExportType(e.target.value)}
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
        >
          <option value="expenses">Expenses</option>
          <option value="incomes">Incomes</option>
          <option value="savingsGoals">Savings Goals</option>
        </select>
      </div>

      {/* Start Date */}
      <div>
        <label className="block text-sm font-medium text-gray-800">
          Start Date:
        </label>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
        />
      </div>

      {/* End Date */}
      <div>
        <label className="block text-sm font-medium text-gray-800">
          End Date:
        </label>
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
        />
      </div>

      {/* Export Button */}
      <div className="text-center">
        <button
          type="submit"
          disabled={isExporting}
          className={`w-full sm:w-auto px-6 py-2 rounded-md shadow text-white ${
            isExporting ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-600 hover:bg-green-700'
          }`}
        >
          {isExporting ? 'Exporting...' : 'Export Data'}
        </button>
      </div>
    </form>
  );
};

export default DataExport;

