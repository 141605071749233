import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';
import { auth } from './firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import SignUp from './components/SignUp';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Sidebar from './components/Sidebar';
import LandingPage from './components/LandingPage';
import ExpenseForm from './components/ExpenseForm';
import IncomeForm from './components/IncomeForm';
import SavingsGoalForm from './components/SavingsGoalForm';
import { Toaster } from 'react-hot-toast';
import ViewBudget from './components/ViewBudget';
import PrivacyPolicy from './components/PrivacyPolicy';
import UserRights from './components/UserRights';
import Profile from './components/Profile';
import ProfileManager from './components/ProfileManager';
import ProtectedRoute from './components/ProtectedRoute';
import { Navigate } from 'react-router-dom';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Added loading state
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false); // Set loading to false after auth state is determined
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (location.pathname !== '/' && user) {
      localStorage.setItem('lastVisitedPath', location.pathname);
    }
  }, [location.pathname, user]);

  useEffect(() => {
    if (!loading && user) {
      const lastVisitedPath = localStorage.getItem('lastVisitedPath');
      if (lastVisitedPath) {
        navigate(lastVisitedPath);
      }
    }
  }, [user, loading, navigate]);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  if (loading) {
    // Optional: Show a loading indicator while auth state is being determined
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col lg:flex-row min-h-screen overflow-hidden">
      {/* Sidebar */}
      {user && (
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
          isCollapsed={isSidebarCollapsed}
          toggleCollapse={() => setIsSidebarCollapsed((prev) => !prev)}
        />
      )}

      {/* Main Content */}
      <div
        className={`flex-grow flex flex-col transition-all duration-300 overflow-y-auto ${
          isSidebarOpen ? 'lg:ml-64' : 'ml-0'
        }`}
      >
        {/* Header */}
        {user && (
          <header
            className={`fixed top-0 left-0 right-0 z-30 flex items-center justify-center bg-white shadow-md p-4 ${
              isSidebarOpen ? 'lg:ml-64' : 'lg:ml-0'
            }`}
            style={{ height: '4rem' }}
          >
            <button
              onClick={toggleSidebar}
              className="absolute left-4 text-gray-700 focus:outline-none lg:hidden"
              aria-label="Toggle Sidebar"
            >
              <FiMenu className="w-6 h-6" />
            </button>
            <h1 className="text-xl font-semibold">Atlas Budget</h1>
          </header>
        )}

        {/* Main Content Section */}
        <main className="flex-grow pt-16 px-4 sm:px-6 lg:px-8 bg-gray-100 overflow-auto w-full">
          <Toaster position="top-right" reverseOrder={false} />
          <Routes>
            <Route
              path="/"
              element={
                loading ? null : user ? <Dashboard /> : <LandingPage />
              }
            />
            <Route
              path="/signup"
              element={
                loading ? null : !user ? <SignUp /> : <Navigate to="/dashboard" replace />
              }
            />
            <Route
              path="/login"
              element={
                loading ? null : !user ? <Login /> : <Navigate to="/dashboard" replace />
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute user={user} loading={loading}>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile-manager"
              element={
                <ProtectedRoute user={user} loading={loading}>
                  <ProfileManager />
                </ProtectedRoute>
              }
            />

            {/* Protected routes */}
            <Route
              path="/expenses"
              element={
                <ProtectedRoute user={user} loading={loading}>
                  <ExpenseForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/income"
              element={
                <ProtectedRoute user={user} loading={loading}>
                  <IncomeForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/savings-goals"
              element={
                <ProtectedRoute user={user} loading={loading}>
                  <SavingsGoalForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/edit-expense/:id"
              element={
                <ProtectedRoute user={user} loading={loading}>
                  <ExpenseForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/view-budget"
              element={
                <ProtectedRoute user={user} loading={loading}>
                  <ViewBudget />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute user={user} loading={loading}>
                  <Dashboard />
                </ProtectedRoute>
              }
            />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/user-rights" element={<UserRights />} />
          </Routes>
        </main>

        {/* Footer */}
        <footer className="bg-gray-800 text-white text-center py-2 text-sm">
          <p>&copy; 2024 Atlas Budget. All rights reserved.</p>
          <div className="mt-2">
            <a href="/privacy-policy" className="text-blue-400 hover:underline mr-4">
              Privacy Policy
            </a>
            <a href="/user-rights" className="text-blue-400 hover:underline mr-4">
              User Rights
            </a>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;






































