// src/firebase-config.js
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage'; // Import the storage function

const firebaseConfig = {
  apiKey: "AIzaSyAmpscyabC4N6HORoLzp1hN_f6vl1eukw8",
  authDomain: "atlas-budget.firebaseapp.com",
  databaseURL: "https://atlas-budget-default-rtdb.firebaseio.com",
  projectId: "atlas-budget",
  storageBucket: "atlas-budget.appspot.com",
  messagingSenderId: "968471535260",
  appId: "1:968471535260:web:1727bfc0378d90e3c49759",
  measurementId: "G-Z2BHM97227"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get the database and auth instances
const database = getDatabase(app);
const auth = getAuth(app);
const storage = getStorage(app); // Initialize storage

export { database, auth, storage };


