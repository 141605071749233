// src/components/Sidebar.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FiHome, FiDollarSign, FiTrendingUp, FiTarget, FiBookOpen, FiLogOut, FiMenu, FiX, FiPlusSquare } from 'react-icons/fi';
import { signOut } from 'firebase/auth';
import { auth, database } from '../firebase-config';
import { ref, get } from 'firebase/database';

const Sidebar = ({ isSidebarOpen, toggleSidebar, isCollapsed, toggleCollapse }) => {
  const [userData, setUserData] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userRef = ref(database, 'users/' + user.uid);
      get(userRef).then((snapshot) => {
        if (snapshot.exists()) {
            const data = snapshot.val();
            console.log('User Data:', data); // Log user data
            setUserData(data);
        }
      });
    }
  }, []);

  const links = [
    { to: '/dashboard', icon: <FiHome />, label: 'Dashboard' },
    { to: '/expenses', icon: <FiDollarSign />, label: 'Expenses' },
    { to: '/income', icon: <FiTrendingUp />, label: 'Income' },
    { to: '/savings-goals', icon: <FiTarget />, label: 'Savings Goals' },
    { to: '/view-budget', icon: <FiBookOpen />, label: 'View Budget' },
    { to: '/Profile', icon: <FiMenu />, label: 'Profile'},
    { to: '/profile-manager', icon: <FiPlusSquare />, label: 'Switch Profile'},
  ];

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div
      className={`fixed top-0 left-0 h-full bg-gray-800 text-white z-40 transition-transform duration-300 transform ${
        isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
      } lg:translate-x-0 ${isCollapsed ? 'lg:w-20' : 'lg:w-64'}`}
    >
      <div className="flex flex-col h-full justify-between">
        {/* Sidebar Header with Logo and Close Button */}
        <div className="flex items-center justify-between p-4">
          {!isCollapsed && <img src={userData.avatar || "/AtlasBudget.png"} alt="User Avatar" className="w-24 h-24 rounded-full mx-auto mb-4" />}
          {/* Close button for mobile view */}
          <button
            onClick={toggleSidebar}
            className="text-white lg:hidden block" // Show this button only on mobile
            aria-label="Close Sidebar"
          >
            <FiX className="w-6 h-6" />
          </button>
          {/* Collapse/Expand button for desktop view */}
          <button onClick={toggleCollapse} className="text-white lg:block hidden" aria-label="Toggle Sidebar">
            {isCollapsed ? <FiMenu /> : <FiX />}
          </button>
        </div>

        <nav className="mt-4 flex-grow overflow-y-auto">
          <ul className="space-y-2">
            {links.map((link, index) => (
              <li key={index}>
                <Link
                  to={link.to}
                  className={`flex items-center p-3 ${
                    location.pathname === link.to ? 'bg-gray-700' : 'hover:bg-gray-700'
                  }`}
                  onClick={() => isSidebarOpen && toggleSidebar()} // Close the sidebar on link click (for mobile)
                >
                  {link.icon}
                  {!isCollapsed && <span className="ml-3">{link.label}</span>}
                </Link>
              </li>
            ))}
          </ul>
        </nav>

        <div className="p-4">
          <button onClick={handleLogout} className="flex items-center p-3 w-full hover:bg-gray-700">
            <FiLogOut />
            {!isCollapsed && <span className="ml-3">Logout</span>}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;










































