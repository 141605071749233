// src/components/Dashboard.js

import React, { useState, useEffect } from 'react';
import { database, auth } from '../firebase-config';
import { ref, onValue } from 'firebase/database';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import {
  format,
  endOfYear,
  startOfMonth,
  addMonths,
  addWeeks,
  addYears,
  isBefore,
  isSameMonth,
} from 'date-fns';

import DataExport from './DataExport';
import DataImport from './DataImport';

import Modal from 'react-modal';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement
);

Modal.setAppElement('#root');

const Dashboard = () => {
  const [yearlyBalances, setYearlyBalances] = useState({});
  const [topSpendingCategories, setTopSpendingCategories] = useState([]);
  const [mostFrequentExpenses, setMostFrequentExpenses] = useState([]);
  const [monthlyExpenses, setMonthlyExpenses] = useState({});
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (!userId) return;

    const monthlyIncome = {};
    const monthlyExpense = {};
    const categoryData = {};
    const monthlyCategoryData = {};
    let localFrequencyData = {};

    const currentYear = new Date().getFullYear();

    const getOccurrencesInYear = (transaction) => {
      const occurrences = [];
      const transactionDate = transaction.date ? new Date(transaction.date) : null;
      if (!transactionDate || isNaN(transactionDate)) return occurrences;

      const endDate = endOfYear(new Date(currentYear, 0));
      let currentDate = new Date(
        Math.max(transactionDate, new Date(currentYear, 0, 1))
      );

      while (currentDate <= endDate) {
        if (transaction.recurrenceEndDate) {
          const recurrenceEndDate = new Date(transaction.recurrenceEndDate);
          if (currentDate > recurrenceEndDate) break;
        }
        occurrences.push(new Date(currentDate));
        if (!transaction.isRecurring || !transaction.recurrenceFrequency) break;

        switch (transaction.recurrenceFrequency) {
          case 'weekly':
            currentDate = addWeeks(currentDate, 1);
            break;
          case 'bi-weekly':
            currentDate = addWeeks(currentDate, 2);
            break;
          case 'monthly':
            currentDate = addMonths(currentDate, 1);
            break;
          case 'yearly':
            currentDate = addYears(currentDate, 1);
            break;
          default:
            currentDate = new Date(endDate.getTime() + 1);
            break;
        }
      }

      return occurrences.filter((date) => date.getFullYear() === currentYear);
    };

    const processIncomes = (data) => {
      if (data) {
        Object.values(data).forEach((income) => {
          const occurrences = getOccurrencesInYear(income);
          occurrences.forEach((occurrenceDate) => {
            const monthKey = format(startOfMonth(occurrenceDate), 'MMMM yyyy');
            if (!monthlyIncome[monthKey]) {
              monthlyIncome[monthKey] = 0;
            }
            monthlyIncome[monthKey] += parseFloat(income.amount) || 0;
          });
        });
      }
    };

    const processExpenses = (data) => {
      if (data) {
        Object.values(data).forEach((expense) => {
          const occurrences = getOccurrencesInYear(expense);
          occurrences.forEach((occurrenceDate) => {
            const monthKey = format(startOfMonth(occurrenceDate), 'MMMM yyyy');
            if (!monthlyExpense[monthKey]) {
              monthlyExpense[monthKey] = 0;
            }
            monthlyExpense[monthKey] += parseFloat(expense.amount) || 0;

            if (expense.category) {
              const category = expense.category;
              if (!categoryData[category]) {
                categoryData[category] = 0;
                monthlyCategoryData[category] = new Set();
              }
              categoryData[category] += parseFloat(expense.amount) || 0;
              monthlyCategoryData[category].add(monthKey);

              if (!localFrequencyData[category]) {
                localFrequencyData[category] = {};
              }
              if (!localFrequencyData[category][monthKey]) {
                localFrequencyData[category][monthKey] = 0;
              }
              localFrequencyData[category][monthKey] += 1;
            }
          });
        });
      }
    };

    const updateYearlyBalances = () => {
      const totalIncome = Object.values(monthlyIncome).reduce((acc, val) => acc + val, 0);
      const totalExpenses = Object.values(monthlyExpense).reduce((acc, val) => acc + val, 0);
      const balance = totalIncome - totalExpenses;

      setYearlyBalances({
        incomes: totalIncome,
        expenses: totalExpenses,
        balance: balance,
      });
    };

    const ensureAllMonthsIncluded = () => {
      let currentMonth = startOfMonth(new Date(currentYear, 0));
      const endMonth = endOfYear(new Date(currentYear, 0));

      while (
        isBefore(currentMonth, endMonth) ||
        isSameMonth(currentMonth, endMonth)
      ) {
        const monthKey = format(currentMonth, 'MMMM yyyy');
        if (!monthlyIncome[monthKey]) monthlyIncome[monthKey] = 0;
        if (!monthlyExpense[monthKey]) monthlyExpense[monthKey] = 0;
        currentMonth = addMonths(currentMonth, 1);
      }
    };

    const fetchTopSpendingCategories = () => {
      const sortedCategories = Object.entries(categoryData)
        .map(([category, total]) => {
          const monthsWithData = monthlyCategoryData[category].size;
          const monthlyAverage = monthsWithData > 0 ? total / monthsWithData : 0;
          return { category, total, monthlyAverage };
        })
        .sort((a, b) => b.total - a.total)
        .slice(0, 5);
      setTopSpendingCategories(sortedCategories);
    };

    const fetchMostFrequentExpenses = () => {
      const sortedFrequencies = Object.entries(localFrequencyData)
        .map(([category, monthlyFreq]) => {
          const totalFrequency = Object.values(monthlyFreq).reduce(
            (acc, freq) => acc + freq,
            0
          );
          return { category, monthlyFreq, totalFrequency };
        })
        .sort((a, b) => b.totalFrequency - a.totalFrequency)
        .slice(0, 5);
      setMostFrequentExpenses(sortedFrequencies);
    };

    const incomeRef = ref(database, `incomes/${userId}`);
    onValue(incomeRef, (snapshot) => {
      const data = snapshot.val();
      processIncomes(data);
      ensureAllMonthsIncluded();
      updateYearlyBalances();
      setMonthlyExpenses({ ...monthlyExpense });
    });

    const expenseRef = ref(database, `expenses/${userId}`);
    onValue(expenseRef, (snapshot) => {
      const data = snapshot.val();
      processExpenses(data);
      ensureAllMonthsIncluded();
      updateYearlyBalances();
      fetchTopSpendingCategories();
      fetchMostFrequentExpenses();
      setMonthlyExpenses({ ...monthlyExpense });
    });
  }, []);

  const totalMonths = Object.keys(monthlyExpenses).length || 12;
  const averageIncome = yearlyBalances.incomes / totalMonths;
  const averageExpenses = yearlyBalances.expenses / totalMonths;
  const averageSavings = yearlyBalances.balance / totalMonths;

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 lg:px-8 py-6">
        <div className="flex flex-col lg:flex-row justify-between items-center mb-6">
          <h3 className="text-2xl font-semibold text-green-700">Dashboard (Year to Date)</h3>
          <div className="flex space-x-2 mt-4 lg:mt-0">
            <button
              onClick={() => setIsImportModalOpen(true)}
              className="bg-blue-600 text-white px-4 py-2 rounded-md shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Import Data
            </button>
            <button
              onClick={() => setIsExportModalOpen(true)}
              className="bg-green-600 text-white px-4 py-2 rounded-md shadow hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
            >
              Export Data
            </button>
          </div>
        </div>

        {/* Financial Overview Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-10">
          <div className="p-6 bg-green-50 rounded-lg shadow flex flex-col items-center">
            <h4 className="text-lg font-semibold text-green-800 mb-2">Total Income (YTD)</h4>
            <p className="text-3xl font-bold text-green-600">${(yearlyBalances.incomes || 0).toFixed(2)}</p>
          </div>
          <div className="p-6 bg-red-50 rounded-lg shadow flex flex-col items-center">
            <h4 className="text-lg font-semibold text-red-800 mb-2">Total Expenses (YTD)</h4>
            <p className="text-3xl font-bold text-red-600">${(yearlyBalances.expenses || 0).toFixed(2)}</p>
          </div>
          <div className="p-6 bg-blue-50 rounded-lg shadow flex flex-col items-center">
            <h4 className="text-lg font-semibold text-blue-800 mb-2">Net Savings (YTD)</h4>
            <p className="text-3xl font-bold text-blue-600">${(yearlyBalances.balance || 0).toFixed(2)}</p>
          </div>
        </div>

        {/* Charts Section */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-10">
          <div className="bg-gray-50 rounded-lg shadow p-6">
            <h4 className="text-lg font-semibold text-gray-800 mb-4 text-center">Income vs. Expenses (YTD)</h4>
            <div className="h-64">
              <Bar
                data={{
                  labels: ['Income', 'Expenses'],
                  datasets: [
                    {
                      label: 'Amount',
                      data: [yearlyBalances.incomes || 0, yearlyBalances.expenses || 0],
                      backgroundColor: ['#34D399', '#F87171'],
                    },
                  ],
                }}
                options={{ responsive: true, maintainAspectRatio: false }}
              />
            </div>
          </div>

          <div className="bg-gray-50 rounded-lg shadow p-6">
            <h4 className="text-lg font-semibold text-gray-800 mb-4 text-center">Monthly Averages (YTD)</h4>
            <div className="h-64">
              <Bar
                data={{
                  labels: ['Average Income', 'Average Expenses', 'Average Savings'],
                  datasets: [
                    {
                      label: 'Amount',
                      data: [averageIncome || 0, averageExpenses || 0, averageSavings || 0],
                      backgroundColor: ['#34D399', '#F87171', '#60A5FA'],
                    },
                  ],
                }}
                options={{ responsive: true, maintainAspectRatio: false }}
              />
            </div>
          </div>
        </div>

        {/* Most Frequent Expenses Chart */}
        <div className="bg-gray-50 rounded-lg shadow p-6 mb-10">
          <h4 className="text-lg font-semibold text-gray-800 mb-4 text-center">Most Frequent Expenses</h4>
          <div className="h-64">
            <Line
              data={{
                labels: Object.keys(monthlyExpenses),
                datasets: mostFrequentExpenses.map((expense, index) => ({
                  label: expense.category,
                  data: Object.keys(monthlyExpenses).map((monthKey) =>
                    expense.monthlyFreq[monthKey] ? expense.monthlyFreq[monthKey] : 0
                  ),
                  fill: false,
                  borderColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'][index % 5],
                  borderDash: index % 2 === 0 ? [] : [5, 5],
                  borderWidth: 2,
                  pointRadius: 4,
                  pointHoverRadius: 6,
                  tension: 0.1,
                })),
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
              }}
            />
          </div>
        </div>

        {/* Top Spending Categories */}
        <div className="bg-gray-50 rounded-lg shadow p-6 mb-10">
          <h4 className="text-lg font-bold text-center mb-4">Top Spending Categories</h4>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white text-center">
              <thead>
                <tr>
                  <th className="py-3 px-4 border-b text-sm font-medium text-gray-800">Category</th>
                  <th className="py-3 px-4 border-b text-sm font-medium text-gray-800">YTD Total ($)</th>
                  <th className="py-3 px-4 border-b text-sm font-medium text-gray-800">Monthly Average ($)</th>
                </tr>
              </thead>
              <tbody>
                {topSpendingCategories.length > 0 ? (
                  topSpendingCategories.map((category, index) => (
                    <tr key={index} className="hover:bg-gray-100">
                      <td className="py-4 px-4 border-b text-gray-700">{category.category}</td>
                      <td className="py-4 px-4 border-b text-gray-700 font-semibold">${category.total.toFixed(2)}</td>
                      <td className="py-4 px-4 border-b text-gray-700 font-semibold">${category.monthlyAverage.toFixed(2)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="py-6 px-4 text-center text-gray-600">No spending data available.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Import Modal */}
        <Modal
          isOpen={isImportModalOpen}
          onRequestClose={() => setIsImportModalOpen(false)}
          contentLabel="Import Data"
          className="fixed inset-0 flex items-center justify-center z-50"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
        >
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-lg font-semibold text-gray-800">Import Data</h4>
              <button
                onClick={() => setIsImportModalOpen(false)}
                className="text-gray-600 hover:text-gray-800 focus:outline-none"
              >
                &times;
              </button>
            </div>
            <DataImport onClose={() => setIsImportModalOpen(false)} />
          </div>
        </Modal>

        {/* Export Modal */}
        <Modal
          isOpen={isExportModalOpen}
          onRequestClose={() => setIsExportModalOpen(false)}
          contentLabel="Export Data"
          className="fixed inset-0 flex items-center justify-center z-50"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
        >
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-lg font-semibold text-gray-800">Export Data</h4>
              <button
                onClick={() => setIsExportModalOpen(false)}
                className="text-gray-600 hover:text-gray-800 focus:outline-none"
              >
                &times;
              </button>
            </div>
            <DataExport onClose={() => setIsExportModalOpen(false)} />
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Dashboard;












































































































































